import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faPrint} from "@fortawesome/free-solid-svg-icons";

import TextHeader from "core/components/TextHeader";
import {Button, Header} from "core/components";
import { isEmpty, isPresent } from "core/utils";
import TheSubmission from "./TheSubmission";
import Feedback from "../feedback/Feedback";
import SubmissionReviewForm from "./SubmissionReviewForm";
import StudentReflection from "../studentReflection/StudentReflection";
import { submissionShow } from "../action";
import { useSnackbar } from 'notistack';
import { isEqual } from "lodash";
import DownloadFeedbackButton from "../feedback/DownloadFeedbackButton";
import {useSchoolConfig} from "core/hooks";
import ConfirmModal from "core/components/modals/Confirm";

const SubmissionModal = ({ show, toggle, currentSubmission, assignment, userOwns, submissions, onSubmissionChanged }) => {
  const [prevButtonText, setPrevButtonText] = useState('Prev')
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false)
  const [nextButtonText, setNextButtonText] = useState('Next');
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [assignmentObjectives, setAssignmentObjectives] = useState([]);
  const [submission, setSubmission] = useState(currentSubmission)
  const [editMode, setEditMode] = useState(false)
  const [uploadTimerID, setUploadTimerID] = useState();
  const [externalTeacherAction, setExternalTeacherAction] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentDirection, setCurrentDirection] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const modalRef = useRef();
  const showReviewForm = editMode || isEmpty(assignment?.feedback_published_at);

  const { nurtureAssistantEnabled } = useSchoolConfig();

  useEffect(() => {
    setSubmission(currentSubmission)
  }, [currentSubmission])

  useEffect(() => {
    modalRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    setEditMode(false);
    setExternalTeacherAction({});
  }, [submission]);

  const max_marks = assignment.max_marks || 100;

  const schema = yup.object().shape({
    'graded': yup.boolean(),
    'personal_feedback': yup.string().nullable(),
    'assignment_objective_ids': yup.array()
      .of(yup.mixed()),
    'grade': yup
      .mixed()
      .nullable()
      .when('graded', (graded) => {
        if (graded === true && assignment.grade_display_to_students !== 'rubrics') {
          return yup.number().required().typeError('Grade is required').min(0).max(max_marks)
        }
      }),
  });

  const { formState: { errors }, handleSubmit, register, setValue, watch } = useForm({
    resolver: yupResolver(schema)
  });

  const oldPersonalFeedback = submission?.teacher_feedback?.personal_feedback;
  const personalFeedbackWatch = watch('personal_feedback');
  const oldGrade = submission?.teacher_feedback?.grade;
  const gradeWatch = watch('grade');
  const oldAssignmentObjectivesIds = submission?.teacher_feedback?.feedback_objectives?.map(f => f.id.toString()) || []

  const locate = async (direction) => {
    const movement = { prev: -1, next: 1 };
    const buttonText = { prev: 'Prev', next: 'Next' };
    const buttonTextFunction = { prev: setPrevButtonText, next: setNextButtonText };
    const buttonDisabledFunction = { prev: setPrevButtonDisabled, next: setNextButtonDisabled };

    try {
      // No need to move to next or prev if only one submission
      if (submissions?.length === 1) return;

      const currentIndex = submissions.findIndex(userAssignment => userAssignment.id === submission.id);
      if (currentIndex === -1) return;

      let newIndex = currentIndex + movement[direction];
      if (newIndex === -1) newIndex = submissions?.length - 1 || currentIndex
      if (newIndex >= submissions?.length) newIndex = 0
      const newSubmission = submissions[newIndex];

      buttonTextFunction[direction]('Loading...')
      buttonDisabledFunction[direction](true)

      const submissionData = await submissionShow(assignment.id, newSubmission.id)
      setSubmission(submissionData)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      buttonTextFunction[direction](buttonText[direction])
      buttonDisabledFunction[direction](false)
    }
  };

  const warnBeforeNavigation = async direction => {
    setCurrentDirection(direction);
    const personalFeedbackChanged = (!!personalFeedbackWatch) &&  personalFeedbackWatch !== oldPersonalFeedback
    const gradeChanged = (!!gradeWatch) && gradeWatch !== oldGrade
    const objectiveChanged = !isEqual(assignmentObjectives.sort(), oldAssignmentObjectivesIds.sort())

    if (personalFeedbackChanged || gradeChanged || objectiveChanged) {
      // eslint-disable-next-line no-restricted-globals
      setShowConfirmModal(true);
    } else {
      await locate(direction);
    }
  };

  const handleSubmissionChange = (updatedSubmission) => {
    setSubmission(updatedSubmission);
    if (typeof onSubmissionChanged == 'function') onSubmissionChanged(updatedSubmission);
  }

  const handleAssignmentObjectivesChanged = (assignmentObjectives) => {
    setAssignmentObjectives(() => assignmentObjectives)
  }

  const handleTeacherUploadSubmission = () => {
    clearInterval(uploadTimerID);

    const timerId = setTimeout(() => {
      setExternalTeacherAction({ type: 'student_submission', value: null});
    }, 5000);
    setUploadTimerID(timerId);
  }

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      modalClassName="fixed-right"
      className={`modal-dialog-vertical ${nurtureAssistantEnabled ? 'submission-modal' : ''}`}
      zIndex={2000000700}
    >
      <ModalHeader toggle={toggle} className="card-header" tag='div'>
        <div className="d-flex flex-wrap align-items-center">
          <TextHeader className="card-header-title me-3">Review Assessment</TextHeader>
        </div>

      </ModalHeader>
      <ModalBody id="assignmentModalBody">
        <div ref={modalRef} />
        <TheSubmission
          assignmentId={assignment.id}
          submission={submission}
          userOwns={userOwns}
          feedbackPublished={assignment.feedback_published_at}
          submissionType={assignment.submission_type}
          setValue={setValue}
          didNotSubmit={submission.did_not_submit}
          onTeacherUploadSubmission={handleTeacherUploadSubmission}
        />

        {/*<Header*/}
        {/*  title="Assessment Feedback"*/}
        {/*  subtitle="Your feedback for this submission."*/}
        {/*  SideButton={<DownloadFeedbackButton submission={submission} assignment={assignment} showButton={!showReviewForm} />}*/}
        {/*/>*/}

        {
          !showReviewForm &&
            <Feedback onEdit={() => setEditMode(true)} assignment={assignment} submission={submission} />
        }

        {
          showReviewForm &&
            <SubmissionReviewForm
              assignment={assignment}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmissionChange={handleSubmissionChange}
              register={register}
              setValue={setValue}
              didNotSubmit={submission.did_not_submit}
              submission={submission}
              watch={watch}
              externalTeacherAction={externalTeacherAction}
              onAssignmentObjectivesChanged={handleAssignmentObjectivesChanged}
              onCancel={() => setEditMode(false)}
            />
        }
        <StudentReflection submission={submission} assignment={assignment} />

        <ConfirmModal
            show={showConfirmModal}
            onConfirm={async () => { setShowConfirmModal(false); await locate(currentDirection) }}
            title="Leave page?"
            text="You have unsaved changes. Are you sure you want to leave this page?"
            toggle={() => setShowConfirmModal(!showConfirmModal)}
        />
      </ModalBody>
      <ModalFooter>
        <div className="row w-100">
          <div className="col">
            <Button
              color="secondary"
              type="button"
              className="btn-sm"
              onClick={() => warnBeforeNavigation('prev')}
              disabled={prevButtonDisabled}>
              <FontAwesomeIcon icon={faArrowLeft} className="me-1" />
              {prevButtonText}
            </Button>
          </div >
          <div className="col text-end">
            <Button
              color="secondary"
              type="button"
              className="btn-sm"
              onClick={() => warnBeforeNavigation('next')}
              disabled={nextButtonDisabled}>
              {nextButtonText}
              <FontAwesomeIcon icon={faArrowRight} className="ms-1" />
            </Button>
          </div>
        </div >
      </ModalFooter >
    </Modal >
  );
}

SubmissionModal.defaultProps = {
  userOwns: false,
  onSubmissionChanged: null,
  submissions: [],
}

SubmissionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  currentSubmission: PropTypes.any.isRequired,
  assignment: PropTypes.any.isRequired,
  userOwns: PropTypes.bool,
  submissions: PropTypes.arrayOf(PropTypes.any),
  onSubmissionChanged: PropTypes.func,
};

export default SubmissionModal;