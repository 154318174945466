import React from 'react';
import { useSelect } from 'downshift';
import Ireland6 from './icons/ireland_6.svg';
import UK6 from './icons/uk_6.svg';
import UK7 from './icons/uk_7.svg';
import UK9 from './icons/uk_9.svg';
import UK10 from './icons/uk_10.svg';
import UK11 from './icons/uk_11.svg';
import './RubricsDropdown.scss';
import PropTypes from "prop-types"; // Import custom styles

const items = [
  {
    value: 'custom_criteria',
    label: 'Custom Criteria',
    icon: null,
    description: 'Any No. of Criteria Labels',
  },
  {
    value: 'junior_cycle',
    label: 'Junior Cycle',
    icon: <img src={Ireland6} />,
    description: 'Not-Graded, Partially Achieved, Achieved, Merit, Higher Merit',
  },
  {
    value: 'gcse_ofqual',
    label: 'GCSE Ofqual',
    icon: <img src={UK11} />,
    description: '9, 8, 7, 6, 5, 4, 3, 2, 1, U',
  },
  {
    value: 'gcse_eani',
    label: 'GCSE EANI',
    icon: <img src={UK10} />,
    description: 'A*, A, B, C*, C, D, E, F, G, U',
  },
  {
    value: 'gcse_wales',
    label: 'GCSE Wales',
    icon: <img src={UK9} />,
    description: 'A*, A, B, C, D, E, F, G, U',
  },
  {
    value: 'a_levels',
    label: 'A Levels',
    icon: <img src={UK7} />,
    description: 'A*, A, B, C, D, E, U',
  },
  {
    value: 'as_levels',
    label: 'AS Levels',
    icon: <img src={UK6} />,
    description: 'A, B, C, D, E, U',
  },
];

const RubricsDropdown = ({ onSelect }) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    selectedItem,
    getItemProps,
  } = useSelect({
    // initialSelectedItem: 'junior_cycle',
    items,
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      // This is where you can handle the selected item
      if (selectedItem) {
        onSelect(selectedItem.value);
      }
    },
  });

  return (
    <>
      <div className="text-white">Criteria labels</div>
      <div className="dropdown-container">
        <button type='button' className="dropdown-button" {...getToggleButtonProps()}>
          {selectedItem ? selectedItem.label : 'Select Criteria'} :{' '}
          {selectedItem ? selectedItem.description : ''}
        </button>
        <ul className={`dropdown-menu ${isOpen ? 'open' : ''}`} {...getMenuProps()}>
          {isOpen &&
            items.map((item, index) => (
              <li
                className={`dropdown-item ${
                  highlightedIndex === index ? 'highlighted' : ''
                }`}
                key={item.value}
                {...getItemProps({item, index})}
              >
                <div className="item-icon">
                  {item.icon}
                </div>
                <div className="item-label">
                  <strong>{item.label} :</strong> {item.description}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

RubricsDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default RubricsDropdown;
