import {envVariables, teamsEnv} from "core/utils";
import metricService from "lib/metricService";
import {parse} from "best-effort-json-parser";

export const streamResponse = (query, onMessage, onError = null) => {
  const { tenantId, channelId } = teamsEnv;

  const url = `${envVariables.apiBase}/api/v1/ms_teams/${tenantId}/channels/${channelId}/ai/stream_response?${query}`
  const eventSource = new EventSource(url);

  eventSource.onmessage = (event) => {
    onMessage(event);
  };

  eventSource.onerror = (error) => {
    console.log('Error occurred', error);
    if (onError) onError();
    eventSource.close();
  };

  return () => {
    eventSource.close();
  };
};

export const parseStreamedAssignmentJson = (string) => {
  try {
    // Remove any backticks and ```json markers.
    let cleanedJson = string.replace(/```json/g, '')
      .replace(/```/g, '')

    // Check if the string starts with an opening brace '{' and add it if missing
    if (!cleanedJson.trim().startsWith('{')) {
      cleanedJson = '{' + cleanedJson.trim();
    }

    // Ensure it ends with a closing brace '}' and add it if missing
    if (!cleanedJson.trim().endsWith('}')) {
      cleanedJson = cleanedJson.trim() + '}';
    }

    // Step 4: Fix missing quotes before "title" key if they are missing
    cleanedJson = cleanedJson.replace(/title\s*":/g, '"title":')
      .replace(/""title"|""title""/, '"title"')

    cleanedJson.replace(/"title\s*"/g, '"title":').replace(/"title"::/g, '"title":')
    return parse(cleanedJson);
  } catch (e) {
    // console.log('error', cumulativeStreamString)
  }
}

export const parseStreamedGroupFeedbackJson = (string) => {
  try {
    // Remove any backticks and ```json markers.
    let cleanedJson = string.replace(/```json/g, '')
      .replace(/```/g, '')

    // Check if the string starts with an opening brace '{' and add it if missing
    if (!cleanedJson.trim().startsWith('{')) {
      cleanedJson = '{' + cleanedJson.trim();
    }

    // Ensure it ends with a closing brace '}' and add it if missing
    if (!cleanedJson.trim().endsWith('}')) {
      cleanedJson = cleanedJson.trim() + '}';
    }

    // Step 4: Fix missing quotes before "title" key if they are missing
    cleanedJson = cleanedJson.replace(/student_performance\s*":/g, '"student_performance":')
      .replace(/""student_performance"|""student_performance""/, '"student_performance"')

    cleanedJson.replace(/"student_performance\s*"/g, '"student_performance":').replace(/"student_performance"::/g, '"student_performance":')
    return parse(cleanedJson);
  } catch (e) {
    // console.log('error', cumulativeStreamString)
  }
}

export const trackDuration = (scope, event = 'ai_latency') => {
  const sessionStartTime = window.sessionStorage.getItem('startTimeInUnix');
  if (sessionStartTime) {
    const end = currentTimeInUnix();

    const durationInSeconds = end - parseInt(sessionStartTime)
    metricService.track({ event: event, properties: { duration: durationInSeconds, scope: scope } });
    if (event === 'ai_latency') resetStartTime();
  }
}

export const setStartTime = () => {
  window.sessionStorage.setItem('startTimeInUnix', currentTimeInUnix().toString());
}

export const resetStartTime = () => {
  window.sessionStorage.removeItem('startTimeInUnix');
}

export const currentTimeInUnix = () => {
  return Math.floor(Date.now() / 1000);
}


