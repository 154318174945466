import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Button, Loader, MarkdownEditorField, NurtureAIFeedback } from "core/components";
import { useDispatch } from "react-redux";
import { suggestComment } from "./action";
import { useSnackbar } from "notistack";
import {useSchoolConfig} from "../../hooks";
import LanguageSupportSwitch from "./LanguageSupportSwitch";
import { FIELDS } from "modules/assignments/feedback/groupFeedback/constants";
import metricService from "lib/metricService";
import {
  parseStreamedGroupFeedbackJson,
  resetStartTime,
  setStartTime,
  streamResponse,
  trackDuration
} from "./helpers";

const NurtureAIGroupFeedbackComment = ({ hint, onSuggest, assignmentObjectiveId }) => {
  const STATES = { hint: 1, working: 2, suggestion: 3 };
  const LOADING_TEXTS = ['Making coffee', 'Generating Feedback', 'Almost done']

  const { channelOnboarding } = useSchoolConfig();
  const languageRelated = channelOnboarding?.data?.language_related;
  const subject = channelOnboarding?.data?.subject;

  const [state, setState] = useState(!!languageRelated ? STATES.hint : STATES.working);
  const [suggested, setSuggested] = useState('');
  const [studentPerformance, setStudentPerformance] = useState('');
  const [nextSteps, setNextSteps] = useState('');
  const [aiRequestId, setAiRequestId] = useState(null);
  const [loadingText, setLoadingText] = useState(LOADING_TEXTS[0]);
  const [languageSupportEnabled, setLanguageSupportEnabled] = useState(!!languageRelated)
  const [streaming, setStreaming] = useState(false);
  const [firstTokenSent, setFirstTokenSent] = useState(false);
  const [streamNextSteps, setStreamNextSteps] = useState('');
  const [streamStudentPerformance, setStreamStudentPerformance] = useState('');


  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  let cumulativeStreamString = '';

  const toggleLoadingText = () => {
    setLoadingText(LOADING_TEXTS[0]);
    let currentIndex = 0
    return setInterval(() => {
      if (currentIndex < LOADING_TEXTS.length - 1) {
        setLoadingText(LOADING_TEXTS[currentIndex + 1])
        currentIndex += 1
      }
    }, 3000);
  }

  const generateSuggestionsAsync = async () => {
    const intervalId = toggleLoadingText();
    setState(STATES.working)
    try {
      // const requestId = uuidv4().replace(/-/g, '');
      // const channelName = `commentsGeneration-${requestId}`;
      // const pusherChannel = pusher.subscribe(channelName);

      const data = {
        hint: hint,
        assignment_objective_id: assignmentObjectiveId,
        language_support_enabled: languageSupportEnabled,
        // request_id: requestId,
        // channel_name: channelName,
        run_in_background: true
      };

      setStartTime();
      const response = await dispatch(suggestComment(data))
      const threadId = response.thread_id;

      streamResponse(`thread_id=${threadId}&group_feedback=true`, handleStreamEvent);

      clearInterval(intervalId);
    } catch (e) {
      resetStartTime();
      enqueueSnackbar(e.message, { variant: 'error' });
      setState(STATES.suggestion);

      metricService.track({ event: 'ai_failed', properties: { scope: 'group_feedback' } });

      clearInterval(intervalId);
    }
  };

  const handleStreamEvent = async (event) => {
    const messageEvent = JSON.parse(event.data);

    switch (messageEvent.event) {
      case 'assistant.group_feedback':
        handleAiSuggestion(messageEvent.data);
        break;

      case 'thread.message.delta':
        if (!firstTokenSent) {
          trackDuration('group_feedback', 'ai_first_token_latency');
          setFirstTokenSent(true);
        }
        setStreaming(true);
        const content = messageEvent?.data.delta.content[0].text.value;
        cumulativeStreamString = cumulativeStreamString + content;
        const parsedJSON = parseStreamedGroupFeedbackJson(cumulativeStreamString) || {};

        if (parsedJSON?.student_performance || parsedJSON.next_steps) {
          setStreamStudentPerformance(parsedJSON.student_performance);
          setStreamNextSteps(parsedJSON.next_steps)
          setState(STATES.suggestion);
        }

        break;

      case 'thread.error':
        handleErrorEvent(messageEvent);
        break;

      case 'thread.run.failed':
        handleErrorEvent(messageEvent);
        break;
    }
  }

  const handleErrorEvent = (event) => {
    setFirstTokenSent(false);
    setStreaming(false);
    cumulativeStreamString = ''
    setStreamNextSteps('');
    setStreamStudentPerformance('');
    enqueueSnackbar('Error occurred while generating feedback', { variant: 'error' });
    setState(STATES.showHint)
    resetStartTime();
    metricService.track({ event: 'ai_failed', properties: { scope: 'group_feedback' } });
  }

  const generateSuggestions = generateSuggestionsAsync

  const handleAiSuggestion = ({ student_performance, next_steps, id }) => {
    cumulativeStreamString = ''
    setStreaming(false);
    setFirstTokenSent(false);
    setStreamNextSteps('');
    setStreamStudentPerformance('');
    setStudentPerformance(student_performance)
    setNextSteps(next_steps)
    setAiRequestId(id);
    setState(STATES.suggestion);

    trackDuration('group_feedback');
    metricService.track({ event: 'ai_success', properties: { scope: 'group_feedback' } });
  }

  const handleSuggestionChange = (value) => {
    setSuggested(value);
  };

  const handleLanguageSupportSwitchChanged = (e) => {
    setLanguageSupportEnabled(!languageSupportEnabled)
  }

  const handleSuggest = () => {
    if (typeof onSuggest === 'function') onSuggest({action_feedback: studentPerformance, next_steps: nextSteps});
    enqueueSnackbar('The suggestion has been applied to the field above', { variant: 'success' });
  };

  const tryAgain = () => {
    setFirstTokenSent(false);
    setStreaming(false);
    cumulativeStreamString = ''
    setStreamNextSteps('');
    setStreamStudentPerformance('');
    setNextSteps('');
    setStudentPerformance('');

    if (!languageRelated) generateSuggestions();
    else setState(STATES.hint)
  };

  useEffect(() => {
    if (!languageRelated) generateSuggestions();
  }, [])

  return (
    <div>
      {
        state === STATES.hint &&
          <>
            <LanguageSupportSwitch
              id="rubrics-language-support-switch"
              labelClass="text-muted"
              label={`Give feedback entirely in subject language: ${subject}`}
              overrideClasses="form-check form-switch ps-0"
              onLanguageSwitchChanged={handleLanguageSupportSwitchChanged}
              checked={languageSupportEnabled} />

            <div className="mt-4">
              <Button color="light" onClick={generateSuggestions}>Generate Suggestion</Button>
            </div>
          </>
      }

      {
        state === STATES.working &&
        <div>
          <div className="d-flex justify-content-center mb-3">
            <Loader type="ring" loading={true} color="#6CBDBF" />
          </div>
          <div className="font-size-15 text-muted mb-3 text-center">{loadingText}...</div>
        </div>
      }

      {
        state === STATES.suggestion &&
        <div>
          <div className="mb-4">
            <label className="form-label text-muted">{ FIELDS[0].label }</label>
            <div>
              <MarkdownEditorField
                className='markdown'
                initialValue={studentPerformance || streamStudentPerformance}
                maxHeight="100px"
                onChange={(v) => setStudentPerformance(v)}
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="form-label text-muted">{ FIELDS[1].label }</label>
            <div>
              <MarkdownEditorField
                className='markdown'
                initialValue={nextSteps || streamNextSteps}
                maxHeight="100px"
                onChange={(v) => setNextSteps(v)}
              />
            </div>
          </div>

          {
            !streaming &&
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="mb-2">
                <NurtureAIFeedback aiRequestId={aiRequestId}/>
              </div>
              <div>
                <Button color="transparent" className="me-2 text-white" onClick={tryAgain}>Retry</Button>
                <Button color="light" onClick={handleSuggest}>Use Suggestion</Button>
              </div>
            </div>
          }

          {
            streaming &&
            <div className='d-flex flex-wrap justify-content-center mt-5 px-3 py-3 border-top'>
              <Loader type="ring" loading={true} color="#6CBDBF"/>
            </div>
          }
        </div>
      }
    </div>
  );
}

NurtureAIGroupFeedbackComment.defaultProps = {
  onSuggest: null
};

NurtureAIGroupFeedbackComment.propTypes = {
  hint: PropTypes.string.isRequired,
  onSuggest: PropTypes.func,
  assignmentObjectiveId: PropTypes.any.isRequired
};

export default NurtureAIGroupFeedbackComment;